@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700");

.pac-item {
  width: 95%;
  margin-left: 2.5%;
  padding: 7px 0 8px 5px;
  line-height: 1;
  cursor: pointer;

  .pac-icon-marker {
    float: left;
    margin-top: 4px;
  }

  .pac-item-query {
    display: flex;
    font-size: 15px;
    padding: 5px 0;
  }

  span {
    &:last-child {
      padding-left: 20px;

      &.pac-matched {
        padding: 0;
      }
    }
  }
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f3f6f7;
  min-height: 18px;
  background-size: 100%;
  background-image: linear-gradient(to right, #f3f6f7 0%, #edeef1 20%, #f3f6f7 40%, #f3f6f7 100%);
  position: relative;
}

.app-content {
  padding: 25px 0px !important;
}

.cardList {
  margin-left: -8px;
  margin-right: -8px;
}

.cardListItem {
  padding: 8px !important;
}

.form-check {
  padding-left: 0px;

  p {
    line-height: 25px;
  }
}

.no-padding {
  padding: 0px;
}

// Google Maps IE11 fix!
.gm-style img[src$=".svg"] {
  width: auto !important;
  height: auto !important;
}

// Align date selection carousel
.react-multi-carousel-list {
  padding-left: 0;
  position: initial !important;

  li {
    padding: 0 0.5rem;
  }
}

// Override butter toast crisp arrow position
.bt-toast {
  > div {
    border-radius: 6px !important;
    box-shadow: 0 0 15px 0 rgba(0, 27, 72, 0.2) !important;
  }
}

// Override butter toast crisp icon spacing
div.bt-toast > div > span {
  padding: 10px 45px 10px 60px !important;

  .bt-icon {
    width: 60px !important;
  }
}
